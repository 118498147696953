import { createGlobalStyle, css } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    // disable ios bounce effect and scroll out of the page
    overscroll-behavior: none;
    ${({ scrollPaddingTop }) => scrollPaddingTop && css`
      scroll-padding-top: ${scrollPaddingTop}
    `}
  }

  body {
    margin: 0;
  }
  
  a {
    text-decoration: none;
  }

  // iOS webkit link element conversion overwriting local styles workaround
  p span > a[href^="tel:"] {
    color: inherit
  }
  
  p {
    white-space: break-spaces;
    margin: 0;
  }
  
  *, *:before, *:after {
    box-sizing: inherit
  }
  * {
    -webkit-font-smoothing: antialiased
  }
  // todo check blog/post/page titles
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: normal;
  }
  
  section h1 {
    margin: 0;
  }
  
  button, input {
    margin: 0;
    font-family: unset;
  }
  
  ul {
    margin: 0;
  }

  .fixed {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 320px;
    height: 50px;
    background: red;
    -webkit-backface-visibility: hidden;
  }
`

export default GlobalStyles
